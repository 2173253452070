import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import * as api from "../../api";
import LoadingSpinner from "../../storybook/LoadingSpinner";
import "./index.scss";
import { RouteComponentProps } from "react-router-dom";
import { AssocitaedAssetsType } from "../../models/Property/AdminUnits";
import Tenancies from "../../models/Customer/Tenancies";
import { CustomerResponse } from "../../models/Customer";
import {
  userChosenPropertyAddress,
  userChosenBalance,
  userChosenAccountNumber,
  userChosenBalanceType,
  userChosenAccountType,
  viewStatementData,
  userChosenAccountIdentifier,
  showMenuTabs,
} from "../../ducks/redux/actions/userChoices";
import { BalanceType } from "../../models/Accounts/BalanceType";
import AccountDetails from "../../models/Accounts/AccountDetails";
import {
  AccountType,
  AccountTypeList,
  getAccountType,
  TenancyType,
  TenancyTypeFD5,
  TenancyTypeRen,
} from "../../models/Accounts/AccountType";
import {
  formatDateWithFullMonthInLetters,
  isNullOrUndefined,
} from "../../utils/formatting";
import TransactionDetails from "../TransactionDetails/index";
import ViewStatement from "../ViewStatement/index";

interface ViewAccountProps extends RouteComponentProps<any> { }
interface ViewAccountProps extends RouteComponentProps<any> { }
const ViewAccount: React.FC<ViewAccountProps> = (props) => {
  const dispatch = useDispatch();
  let PropertyId = useSelector(
    (state: any) => state.userChoicesReducer.userChosenPropertyId
  );
  const [defaultassocitaedAsset, setdefaultassocitaedAsset] = useState("");
  const [defaultAccount, setdefaultAccount] = useState("");
  const [selectedAccount, setSelectedAccount] = useState<AccountDetails | null>(
    null
  );
  const [isApiFail, setApiFailMessage] = useState(false);
  const [payref, setpayref] = useState("");
  const [Area, setArea] = useState(false);
  const [multipleAccunt, setMultipleAccount] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const arrayArea: Array<{ key: string; value: string }> = [];
  const array: AccountDetails[] = [];
  const account: any[] = [];
  const [accountResponse, setaccountCustomerResponse] = useState(account);
  const [accountdetails, setaccountdetails] = useState(array);
  const [associtaedAssetList, setassocitaedAssetsList] = useState(arrayArea);
  const [CustResponse, setCustomerResponse] = useState<CustomerResponse>();
  const [tenancySummary, setTenancySummary] = useState<any>();
  const [isViewStatement, setIsViewStatement] = React.useState("");

  const customerID = useSelector((state: any) => state.addresses?.customerid);
  const { t } = useTranslation();
  let associtaedAssets: any[] = [];

  useEffect(() => {
    dispatch(showMenuTabs(true));
    (async () => {
      try {
        const response = await api.GetAppConfigToggleValue("IsViewStatement");
        setIsViewStatement(response?.value);
      } catch (e: any) {
        console.log("error", e);
      }
    })();
  }, []);

  useEffect(() => {
    (async () => {
      try {
        setIsLoading(false);
        const customerResponse = await api.getCustomer(customerID);
        const accountCustomerResponse = await api.getAccountByCustomers(
          customerID
        );
        setaccountCustomerResponse(accountCustomerResponse);
        setCustomerResponse(customerResponse);
        const activeTenanciesSummaries =
          customerResponse.tenanciesSummaries.filter(
            (ts) =>
              ts.tenancySummary.statuses.find(
                (s) => s.type === "isTenancyActive"
              )?.value === true
          );
        const activeTenanciesPremisesIds: Tenancies[] = activeTenanciesSummaries
          .map((ts: any) => ts.tenancySummary.premisesIds)
          .flat();
        const promises = activeTenanciesPremisesIds.map((item: any) => {
          return api.getAssociatedAssets(item);
        });
        Promise.all(promises).then((results) => {
          //Filter results as per premisesid

          activeTenanciesPremisesIds?.map(async (assetid: any) => {
            results.forEach((element) => {
              const resultF = element.filter(
                (addressdata) => addressdata.id === assetid
              );

              if (resultF !== null) associtaedAssets.push(resultF);
            });
          });
          const associtaedAssetsList: AssocitaedAssetsType[] = [];

          associtaedAssets?.map((addressdata: any) => {
            addressdata?.map((address: any) => {
              const associtaedAssetsTypeEntry: AssocitaedAssetsType =
                new AssocitaedAssetsType();

              associtaedAssetsTypeEntry.key = address?.id;
              associtaedAssetsTypeEntry.value =
                address?.addresses?.postalAddresses[0]?.formattedAddress;
              associtaedAssetsList.push(associtaedAssetsTypeEntry);
            });
          });
          if (associtaedAssetsList.length > 1) {
            setArea(true);
          } else {
            setArea(false);
          }
          if (PropertyId != "") {
            setdefaultassocitaedAsset(PropertyId);
          } else {
            PropertyId = associtaedAssetsList[0]?.key;
            setdefaultassocitaedAsset(associtaedAssetsList[0]?.key);
          }
          if (
            activeTenanciesSummaries != undefined &&
            activeTenanciesSummaries != null
          ) {
            let tenancySummaryEntry = activeTenanciesSummaries.find(
              (st) => st.tenancySummary.premisesIds[0] === PropertyId
            );
            setTenancySummary(tenancySummaryEntry);
            if (
              tenancySummaryEntry != undefined &&
              tenancySummaryEntry != null
            ) {
              let paymentReference =
                tenancySummaryEntry?.tenancySummary?.paymentReference;
              if (paymentReference != undefined && paymentReference != null) {
                getAccountList(paymentReference, accountCustomerResponse);
              }
            }
          }
          setassocitaedAssetsList(associtaedAssetsList);
          const propety = associtaedAssetsList.filter(
            (x) => x.key === PropertyId
          );
          dispatch(userChosenPropertyAddress(propety[0]?.value));
        });
      } catch (e) {
        setIsLoading(true);
        setApiFailMessage(true);
        console.error(e);
      }
    })();
  }, []);

  const backButtonHandler = () => {
    props.history.push("/customer360View");
  };
  const callPageHandler = async (
    AccountNumber: string | null,
    Payref: string | null,
    AccountType: string | null,
    LiveBalance: string,
    LiveBalanceType: string | null
  ) => {
    let LiveBalanceValue = "£" + LiveBalance;
    dispatch(userChosenBalanceType(LiveBalanceType));
    dispatch(userChosenAccountNumber(Payref));
    dispatch(userChosenBalance(LiveBalanceValue));
    dispatch(userChosenAccountType(AccountType));

    props.history.push("/transactionDetails");
  };

  const viewStatementHandler = (
    Payref: string | null,
    AccountType: string | null,
    AccountNumber: string | null
  ) => {
    dispatch(userChosenAccountNumber(Payref));
    dispatch(userChosenAccountType(AccountType));
    dispatch(userChosenAccountIdentifier(AccountNumber));
    const currentAccount = accountResponse.filter(
      (el) => el["account-identifier"] === Payref
    );

    const statementData = {
      tenancyStartDate: tenancySummary.tenancySummary?.tenancyStartDate,
      currentAccount: currentAccount,
      getCustomerResponse: CustResponse,
    };
    dispatch(viewStatementData(statementData));
    props.history.push("/viewstatement");
  };

  const callPageHandlerPayment = async (
    AccountNumber: string | null,
    Payref: string | null,
    AccountType: string | null,
    LiveBalance: string,
    LiveBalanceType: string | null
  ) => {
    dispatch(userChosenBalanceType(LiveBalanceType));
    dispatch(userChosenAccountNumber(Payref));
    if (LiveBalanceType === "in Credit") {
      dispatch(userChosenBalance(0.0));
    } else {
      dispatch(userChosenBalance(Number(LiveBalance).toFixed(2)));
    }
    dispatch(userChosenAccountType(AccountType));

    props.history.push("/paymentDetails");
  };
  const handleChangeAssocitae = (e: { target: { value: any } }) => {
    setdefaultassocitaedAsset(e.target.value);
    const activeTenanciesSummaries = CustResponse?.tenanciesSummaries.filter(
      (ts) =>
        ts.tenancySummary.statuses.find((s) => s.type === "isTenancyActive")
          ?.value === true
    );

    if (
      activeTenanciesSummaries != undefined &&
      activeTenanciesSummaries != null
    ) {
      let TenancySummaryEntry = activeTenanciesSummaries.find(
        (st) => st.tenancySummary.premisesIds[0] === e.target.value
      );
      setTenancySummary(TenancySummaryEntry);
      if (TenancySummaryEntry != undefined && TenancySummaryEntry != null) {
        let paymentReference =
          TenancySummaryEntry?.tenancySummary?.paymentReference;
        if (paymentReference != undefined && paymentReference != null) {
          getAccountList(paymentReference, accountResponse);
        }
      }
    }
    const property = associtaedAssetList.filter(
      (x) => x.key === e.target.value
    );
    dispatch(userChosenPropertyAddress(property[0]?.value));
  };

  const handleChangeAccount = (e: { target: { value: any } }) => {
    setdefaultAccount(e.target.value);
    const chnagedAccountDetails = accountdetails.find(
      (account) => account.AccountNumber === e.target.value
    );
    setSelectedAccount(chnagedAccountDetails);
  };

  const getAccountList = (accountid: string, accountResponse: any) => {
    const accountDetailsList: AccountDetails[] = [];
    let orderAccountDetailsList: AccountDetails[] = [];
    accountResponse.forEach((accountBalance: any) => {
      const accountDetails: AccountDetails = new AccountDetails();
      if (
        accountBalance.identifiers["pay-ref"].substring(0, 6) == accountid ||
        accountBalance.identifiers["pay-ref"].substring(0, 7) == accountid
      ) {
        setpayref(accountBalance.identifiers["pay-ref"]);
        if (accountBalance["account-type"] === AccountTypeList.REN) {
          accountDetails.DisplayNumber = 1;
          accountDetails.AccountType = getAccountType(
            AccountTypeList.REN,
            accountBalance["tenancy-type"]
          );
          accountDetails.Payref = accountBalance.identifiers["pay-ref"];
        } else if (accountBalance["account-type"] === AccountTypeList.FD5) {
          accountDetails.DisplayNumber = 2;
          accountDetails.AccountType = getAccountType(
            AccountTypeList.FD5,
            accountBalance["tenancy-type"]
          );
          accountDetails.Payref = accountBalance.identifiers["pay-ref"];
        } else if (accountBalance["account-type"] === AccountTypeList.MJW) {
          accountDetails.DisplayNumber = 3;
          accountDetails.AccountType = getAccountType(
            AccountTypeList.MJW,
            accountBalance["tenancy-type"]
          );
          accountDetails.Payref = accountBalance.identifiers["pay-ref"];
        } else if (accountBalance["account-type"] === AccountTypeList.GRD) {
          accountDetails.DisplayNumber = 4;
          accountDetails.Payref = accountBalance.identifiers["pay-ref"];
          accountDetails.AccountType = getAccountType(
            AccountTypeList.GRD,
            accountBalance["tenancy-type"]
          );
        } else if (accountBalance["account-type"] === AccountTypeList.DEP) {
          accountDetails.DisplayNumber = 5;
          accountDetails.AccountType = getAccountType(
            AccountTypeList.DEP,
            accountBalance["tenancy-type"]
          );
          accountDetails.Payref = accountBalance.identifiers["pay-ref"];
        } else if (accountBalance["account-type"] === AccountTypeList.HOM) {
          accountDetails.DisplayNumber = 6;
          accountDetails.AccountType = getAccountType(
            AccountTypeList.HOM,
            accountBalance["tenancy-type"]
          );
          accountDetails.Payref = accountBalance.identifiers["pay-ref"];
        }
        accountDetails.AccountNumber = accountBalance["account-identifier"];
        accountDetails.lastTransactionBalance =
          accountBalance["last-transaction"]?.amount?.gross?.value != null
            ? accountBalance["last-transaction"]?.amount?.gross?.value
            : 0.0;
        accountDetails.lastTransactionDate =
          accountBalance["last-transaction"]?.dates["created-date"];
        accountBalance["balance"].forEach((balance: any) => {
          if (balance["balance-type"] == BalanceType.Livebalanced) {
            let liveBalanced = balance["monetary-amount"]["value"];
            const accountBalanceValue = parseInt(liveBalanced);
            accountDetails.LiveBalanceType =
              accountBalanceValue != null
                ? accountBalanceValue > 0
                  ? "in arrears"
                  : "in Credit"
                : "";
            let number =
              liveBalanced != null
                ? Math.abs(liveBalanced.toString().replace(/,/g, ""))
                : 0.0;
            accountDetails.LiveBalance = number.toFixed(2);
            if (liveBalanced == 0) {
              accountDetails.LiveBalanceType = "";
            }
            accountDetailsList.push(accountDetails);
          }
        });
      }
    });
    orderAccountDetailsList = accountDetailsList.filter(
      (x) => x.DisplayNumber != 0
    );

    const sortedAccountList = orderAccountDetailsList.sort((a, b) => {
      return a.DisplayNumber - b.DisplayNumber;
    });
    if (sortedAccountList?.length > 1) {
      setMultipleAccount(true);
    }
    setdefaultAccount(sortedAccountList[0]?.AccountNumber);
    setSelectedAccount(sortedAccountList[0]);
    setaccountdetails(sortedAccountList);
    setIsLoading(true);
  };

  const getAccountAllDetails = useCallback(() => {
    if (isApiFail) {
      return (
        <div
          id="div_apifail"
          className="alertpayment alert-warningpayment error-reason mar-left global-txt"
          role="alert"
        >
          {t("account_Down_SSC001")}
        </div>
      );
    } else {
      if (selectedAccount) {
        return (
          <div className="mb-8  py-2">
            <div className="py-2 grid grid-cols-1 md:grid-cols-5  gap-2">
              <div className="mb-2">
                <strong>{t("Balance")}</strong>
                <span
                  className={
                    selectedAccount?.LiveBalanceType === "in arrears"
                      ? "error-color"
                      : "color-black"
                  }
                >
                  <br /> £{selectedAccount?.LiveBalance}{" "}
                  {selectedAccount?.LiveBalanceType}
                </span>
              </div>

              <div className="mb-2 global-txt">
                <strong>{t("Last_Payment_amount")}</strong>
                <br /> £{selectedAccount?.lastTransactionBalance}
              </div>

              <div className="mb-2 global-txt">
                <strong data-testid="account-type-title">
                  {t("Account_Type")}
                </strong>
                <br /> {selectedAccount?.AccountType}
              </div>

              <div className="mb-2 global-txt">
                <strong>{t("Last_Payment_date")}</strong>
                <br />{" "}
                {isNullOrUndefined(selectedAccount?.lastTransactionDate)
                  ? "-"
                  : formatDateWithFullMonthInLetters(
                    selectedAccount?.lastTransactionDate
                  )}{" "}
              </div>
              {!multipleAccunt && (
                <div className="mb-2 global-txt">
                  <strong>{t("Account_number")}</strong>
                  <br /> {selectedAccount?.Payref}
                </div>
              )
              }

            </div>

            {selectedAccount?.AccountType != AccountType.DEP &&
              isViewStatement === "true" && (
                <>
                  <hr></hr>
                  <div className="mb-4">
                    <ViewStatement
                      Payref={selectedAccount?.Payref}
                      accountType={selectedAccount?.AccountType}
                      accountnumber={selectedAccount?.AccountNumber}
                    ></ViewStatement>
                  </div>
                </>
              )}

            <hr></hr>

            <div className="mb-4">
              <TransactionDetails
                accountType={selectedAccount?.AccountType}
                accountnumber={selectedAccount?.Payref}
                balance={selectedAccount?.LiveBalance}
                balancetype={selectedAccount?.LiveBalanceType}
              ></TransactionDetails>
            </div>
          </div>
        );
      } else {
        return (
          <div
            id="div_apilength"
            className="alertpayment alert-warningpayment error-reason mar-left global-txt"
            role="alert"
          >
            {t("account_Down_SSC001")}
          </div>
        );
      }
    }
  }, [selectedAccount]);

  return (
    <>
      {isLoading ? (
        <div className="padding-repair container-fluid">
          <div className="balance-container mt-24">
            <div className="balance-header">
              <h1>{t("Balance_Header")}</h1>
            </div>
            <div className="balance-buttons">
              {!isApiFail ? (
                <div>
                  {selectedAccount?.AccountType === AccountType.REN ? (
                    <a
                      onClick={() =>
                        window.open(
                          "https://www.peabody.org.uk/your-home/rent-and-service-charges/how-to-pay/direct-debit",
                          "_blank",
                          "noopener"
                        )
                      }
                      onKeyDown={() =>
                        window.open(
                          "https://www.peabody.org.uk/your-home/rent-and-service-charges/how-to-pay/direct-debit",
                          "_blank",
                          "noopener"
                        )
                      }
                      className="secondary-ghost-btn mr-16"
                      id="Create-direct"
                      data-testid={
                        "Create-direct" + selectedAccount?.DisplayNumber
                      }
                    >
                      {" "}
                      {t("Create_direct_debit")}{" "}
                    </a>
                  ) : (
                    <div></div>
                  )}

                  {selectedAccount?.AccountType != AccountType.DEP ? (
                    <a
                      href="#"
                      onClick={(event) =>
                        callPageHandlerPayment(
                          selectedAccount?.AccountNumber,
                          selectedAccount?.Payref,
                          selectedAccount?.AccountType,
                          selectedAccount?.LiveBalance,
                          selectedAccount?.LiveBalanceType
                        )
                      }
                      className="primary-btn"
                      id="make-Payment"
                      data-testid="make-Payment"
                    >
                      {" "}
                      {t("make_Payment")}{" "}
                    </a>
                  ) : (
                    <div></div>
                  )}
                </div>
              ) : (
                <div></div>
              )}
            </div>
          </div>

          <div>
            {Area ? (
              <div className="search-addressdd">
                <select
                  data-testid="viewaccount-search-address"
                  value={defaultassocitaedAsset}
                  id="viewaccount-address"
                  className="text-select"
                  onChange={handleChangeAssocitae}
                >
                  {associtaedAssetList.map((e, key) => {
                    return (
                      <option key={key} value={e.key}>
                        {" "}
                        {e.value}
                      </option>
                    );
                  })}
                </select>
              </div>
            ) : (
              <div
                data-testid="repairs-search-address"
                id="repairs-search-address"
              >
                {associtaedAssetList.map((e, key) => {
                  return (
                    <div>
                      <div className="block">
                        <div className="text-tenancy pt-16">
                          {e.value}
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            )}
          </div>

          {multipleAccunt ? (
            <div className="search-addressdd">
              <select
                data-testid="viewaccount-account-address"
                value={defaultAccount}
                id="viewaccount-account"
                className="text-select option-width"
                onChange={handleChangeAccount}
              >
                {accountdetails.map((e, key) => {
                  return (
                    <option  key={key} value={e.AccountNumber}>
                      {" "}
                      {t("Account")} {e.Payref}
                    </option>
                  );
                })}
              </select>
            </div>
          ) : (
            <></>
          )}

          {getAccountAllDetails()}
        </div>
      ) : (
        <div className="SpinnerWrapper">
          <LoadingSpinner />
        </div>
      )}
    </>
  );
};

export default ViewAccount;
